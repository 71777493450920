import * as React from "react";
import eddyWolffJp from "../../images/eddy_wolff.png";

interface EddyWolffJpProps {
  width?: number;
  className?: string;
}

export default function EddyWolffJp({
  className = "",
  width,
}: EddyWolffJpProps) {
  return (
    <div className={className}>
      <img
        src={eddyWolffJp}
        width={width}
        alt={"Eddy and Wolff in Japanese Characters"}
      />
    </div>
  );
}
