import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import Section from "../components/section";
import EddyWolffJp from "../components/eddy-wolff-jp";
import Characters from "../components/characters";
import SocialIcons from "../components/social-icons";
import AnimateOnScroll from "../components/animate-on-scroll";

export default function IndexPage() {
  return (
    <Layout>
      <Section>
        <Container className={"justify-center flex-wrap gap-8 md:gap-2"}>
          <AnimateOnScroll transition={"fade-in"}>
            <div className={"flex w-full justify-center items-center relative"}>
              <EddyWolffJp className={"hidden md:block"} />
              <Characters className={"flex items-end md:-left-10 relative"} />
            </div>
            <div
              className={"w-full mt-8 md:mt-0 flex gap-8 flex-col items-center"}
            >
              <h2 className={"text-center uppercase font-semibold text-md"}>
                44 Commerce Drive
                <br />
                Robina 4226 QLD
              </h2>
              <SocialIcons />
            </div>
          </AnimateOnScroll>
        </Container>
      </Section>
    </Layout>
  );
}
