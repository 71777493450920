import * as React from "react";
import { FaEnvelope, FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import SocialIcon from "../social-icon";

interface SocialIconsProps {
  className?: string;
}

export default function SocialIcons({ className = "" }: SocialIconsProps) {
  const classNames =
    "bg-gray-600 text-gray-300 hover:bg-primary hover:text-gray-600";

  return (
    <div className={`flex gap-4 ${className}`}>
      <SocialIcon
        className={classNames}
        href={"tel:0481838433"}
        icon={<FaPhone />}
        title={"Call Eddy and Wolff"}
      />
      <SocialIcon
        className={classNames}
        href={"mailto:meetyou@eddyandwolff.com.au"}
        icon={<FaEnvelope />}
        title={"Email Eddy and Wolff"}
      />
      <SocialIcon
        className={classNames}
        href={"https://www.instagram.com/eddyandwolff/"}
        icon={<FaInstagram />}
        title={"Visit Eddy and Wolff's Instagram"}
      />
      <SocialIcon
        className={classNames}
        href={"https://www.facebook.com/eddyandwolff/"}
        icon={<FaFacebookF />}
        title={"Visit Eddy and Wolff's Facebook"}
      />
    </div>
  );
}
